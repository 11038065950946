import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <img src={logo} alt="Logo" className="App-logo"/>
    </div>
  );
}

export default App;
